@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 전역 스타일 */
body {
  font-family: 'Pretendard-Regular', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main .search-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.main .search-box input {
  padding: 10px;
  width: 300px;
}

.main .search-box button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.main-content {
  text-align: center;
  margin-top: 120px;
}

header {
  display: flex;
  justify-content: space-between;
}

.Headerlogo{
  font-size: 100px;
  font-weight: bold;
  font-weight: bold;
  color: #ffffff; /* 글자 색상 하얀색으로 변경 */
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.header nav ul li a {
  text-decoration: none;
  border: 1px solid #fff;
  color: #333;
}


.sub-section {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #fff;

}

.sub-section .left img {
  width: 100%;
  max-width: 500px;
  
  display: flex;
  border: 1px solid #fff;
}

.sub-section .right .image-box {
  width: 100px;
  height: 100px;
  background-color: #f1f1f1;
  margin: 10px;
}

footer {
  padding: 20px;
  border: 1px solid #fff;
  text-align: center;
  background-image:url(./images/FooterBG.png);
}


.search-container {
  position: relative;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.polygon-shape {
  display: flex;
  position: absolute;
  top: 20px; /* 위치 조정 */
  width: calc(80% + 20px); /* 크기 조정 */
  height: calc(100% + 20px); /* 크기 조정 */
  background-color: transparent; /* 배경색을 투명하게 */
  z-index: 0;
}
.btn.red, .btn-two.red       {background-color: #fa5a5a;}
/* default button style */
.btn {
  position: relative;
  border: 0;
  padding: 15px 25px;
  display: inline-block;
  text-align: center;
  color: white;
}
.btn:active {
  top: 4px; 
}
.btn-two {
  color: white; 
  padding: 10px 25px;
  display: inline-block;
  border: 1px solid rgba(0,0,0,0.21);
  border-bottom-color: rgba(0,0,0,0.34);
  text-shadow:0 1px 0 rgba(0,0,0,0.15);
  box-shadow: 0 1px 0 rgba(255,255,255,0.34) inset, 
              0 2px 0 -1px rgba(0,0,0,0.13), 
              0 3px 0 -1px rgba(0,0,0,0.08), 
              0 3px 13px -1px rgba(0,0,0,0.21);
}
.btn-two:active {
  top: 1px;
  border-color: rgba(0,0,0,0.34) rgba(0,0,0,0.21) rgba(0,0,0,0.21);
  box-shadow: 0 1px 0 rgba(255,255,255,0.89),0 1px rgba(0,0,0,0.05) inset;
  position: relative;
}
.polygon-shape::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f1f1f1; /* 배경색 */
  clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0% 50%); /* 다각형 형태 */
  z-index: -1;
}

.polygon-shape::after {
  content: "";
  position: absolute;
  top: -6px; left: -6px; right: -6px; bottom: -6px; /* 테두리 두께만큼 바깥으로 */
  background-color: transparent; /* 배경색을 투명하게 */
  clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0% 50%); /* 다각형 형태 */
  border: 6px solid #808080; /* 테두리 추가 (두께: 2px, 스타일: solid, 색상: #808080) */
  z-index: 0;
}

.search-box {
  position: relative;
  width: 500px;
  top:30px;
  /* 기타 스타일은 이전과 동일 */
}
.App-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #2d3748;
  padding-bottom: 100px; /* Add space for footer */
}

.App-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2d3748;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}