/* CSS 스타일 */
.remember-login {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.remember-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
  color: #a0a0a0;
  display: inline-flex;
  align-items: center;
}

.remember-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.remember-me {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 14px;
}

.remember-me input[type="checkbox"] {
  margin-right: 8px;
}

.remember-me label {
  cursor: pointer;
}
.checkbox-custom {
  position: absolute;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid #4d5154;
  border-radius: 3px;
}

.remember-label:hover .remember-checkbox ~ .checkbox-custom {
  border-color: #3498db;
}

.remember-checkbox:checked ~ .checkbox-custom {
  background-color: #3498db;
  border-color: #3498db;
}

.checkbox-custom:after {
  content: "";
  position: absolute;
  display: none;
}

.remember-checkbox:checked ~ .checkbox-custom:after {
  display: block;
}

.remember-label .checkbox-custom:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.login-container {
    display: flex;
    justify-content: center;
    background-color: #2d3748;
    padding: 2rem;
    height: calc(100vh - 6rem);

  }
  .reg_container{
    display: flex;
    background-color: #2d3748;
    justify-content: center;
    padding: 2rem;
    height: calc(100vh - 6rem);
    @media (max-width: 768px) {
      margin-top: 0rem;
      padding:1.5rem;
    }
  }
  /* 자동 로그인 체크박스 스타일 */

.form-box {
    position: relative;
    padding: 1.5rem;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    color:#fff;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      height: auto;
      padding:1rem;
    }
  }
  
  .form-box h2 {
    font-size: 32px;
    color: #fff;
    text-align: center;
  }
  
  .input-register {
    position: relative;
    width: 310px;
    border-bottom: 2px solid #fff;
    margin: 20px 0;
  }
  
  .input-register input {
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    padding: 0 20px 0 5px;
    font-size: 16px;
    color: #fff;
  }
  /* 자동완성 배경색 제거 */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2d3748 inset !important;
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
}
  .input-register input::placeholder {
    color: #fff;
  }
  
  i {
    position: absolute;
    right: 0;
    top: 13px;
    color: #fff;
  }
  
  .registerBtn {
    width: 100%;
    height: 50px;
    background: dodgerblue;
    border-radius: 5px;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    margin: 20px 0px;
  }
  
  .group {
    display: flex;
    justify-content: space-between;
  }
  
  .group span a {
    position: relative;
    top: 10px;
    color: #fff;
    font-weight: 500;
  }
  
  .group a:focus {
    text-decoration: underline;
  }
  
  #result {
    position: relative;
    top: 25px;
    color: rgb(41, 166, 224);
    font-weight: 600;
  }
  
  .popup {
    visibility: hidden;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%) scale(0);
    width: 400px;
    height: 330px;
    background: #fff;
    border-radius: 6px;
    padding: 0 30px 30px;
    color: #000;
    text-align: center;
    transition: transform 0.4s, top 0.4s;
  }
  
  .popup ion-icon {
    font-size: 50px;
    color: #00ff00;
    padding-top: 45px;
  }
  
  .popup button {
    width: 100%;
    background: #00ff00;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    margin-top: 50px;
    border: none;
    outline: none;
    font-size: 18px;
    color: #777;
    font-weight: 600;
    cursor: pointer;
  }
  
  .popup a {
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
  }
  
  .open-slide {
    visibility: visible;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  .login-box {
    padding:3rem;
    flex-direction: column;
    position: relative;
    height: 500px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    
  @media (max-width: 768px) {
    padding:0rem;
  }
}

.login-box h2 {
    font-size: 32px;
    color: #fff;
    text-align: center;
}

.input-login {
    position: relative;
    width: 310px;
    border-bottom: 2px solid #fff;
    margin: 30px 0;
}

.input-login input {
    width: 100%;
    height: 45px;
    background: transparent;
    border: none;
    outline: none;
    padding: 0 20px 0 5px;
    font-size: 16px;
    color: #fff;
}

/* 자동완성 스타일 수정 */
.input-login input:-webkit-autofill,
.input-login input:-webkit-autofill:hover,
.input-login input:-webkit-autofill:focus,
.input-login input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    -webkit-text-fill-color: #fff !important;
    transition: background-color 5000s ease-in-out 0s;
}

.input-login input::placeholder {
    color: #fff;
}

.loginIdInput {
    border: none;
}

  .loginBtn {
    width: 20rem;
    height: 50px;
    background: dodgerblue;
    border-radius: 5px;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    margin: 20px 0px;
  }

  .forgetSec {
    display: flex;
  }

  .forgetSec a {
    font-size: 12px;
    margin: 0px 30px;

  }

  .input-register-group {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* 필요에 따라 간격 조절 */
    width: 20rem;

  }
  
  .input-register-radio {
    display: flex;
    justify-content: space-evenly;
  }
  
  .input-register select {
    align-items: center;
    width: 100%; /* 전체 너비 사용 */
    padding: 8px 10px; /* 패딩 조정 */
    border: 1px solid #dddddd59; /* 테두리 설정 */
    border-radius: 4px; /* 둥근 모서리 */
    background-color: transparent; /* 배경색 투명 */
    -webkit-appearance: none; /* 기본 디자인 제거 */
    -moz-appearance: none; /* 기본 디자인 제거 */
    appearance: none; /* 기본 디자인 제거 */
    font-size: 16px; /* 폰트 크기 */
    color: #979797; /* 글자색 */
    text-align: center;
  }
  
  .input-register select:focus {
    outline: none; /* 포커스 시 아웃라인 제거 */
    border-color: #2969c9; /* 포커스 시 테두리 색상 변경 */
  }
  

  
  .input-register::after {
    position: absolute; /* 절대 위치 설정 */
    right: 10px; /* 오른쪽에서 10px */
    top: 100%; /* 상단에서 50% */
    transform: translateY(-50%); /* Y축으로 -50% 이동하여 중앙 정렬 */
    pointer-events: none; /* 클릭 이벤트 무시 */
    color: #333; /* 화살표 색상 */
  }

  .registerLink {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .form-box {
      border-style: none;
    }
    .login-box{
      border-style: none;
    }
  }