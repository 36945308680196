.container {
    max-width: 64rem;
    margin: 0 auto;
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
  
  .title {
    font-size: 1.875rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .subtitle {
    font-size: 2.25rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .instructions {
    margin-bottom: 2rem;
  }
  
  .section-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .list {
    list-style-type: disc;
    list-style-position: inside;
  }
  
  .attendance-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e2e8f0;
  }
  
  .attendance-table th, .attendance-table td {
    border: 1px solid #e2e8f0;
    padding: 0.5rem;
    text-align: center;
  }
  
 